import firebase from "firebase/app";

// These imports load individual services into the firebase namespace.
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCqsSr4Mfzt76m4guDbu2_gbT5euylExes",
  authDomain: "booking-7589e.firebaseapp.com",
  databaseURL: "https://booking-7589e.firebaseio.com",
  projectId: "booking-7589e",
  storageBucket: "booking-7589e.appspot.com",
  messagingSenderId: "257338805660",
  appId: "1:257338805660:web:e9646f44cd90c9d2750c59",
  measurementId: "G-DNQT2JQVK5",
});

export default firebaseApp;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { fiFI } from "@material-ui/core/locale";
import { teal, amber } from "@material-ui/core/colors";
import {
  CssBaseline,
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import AppContextProvider from "./context";
import Views from "./views";

import "./index.css";
import "moment/locale/fi";
import * as serviceWorker from "./serviceWorker";

const theme = responsiveFontSizes(
  createMuiTheme(
    {
      palette: {
        primary: teal,
        secondary: amber,
        type: "dark",
      },
    },
    fiFI
  )
);

const Root = () => (
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppContextProvider>
            <CssBaseline />
            <Views />
          </AppContextProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

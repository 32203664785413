import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import { CircularProgress } from "@material-ui/core";

const Login = lazy(() => import("../components/Login"));
const Header = lazy(() => import("../components/Header"));
const Main = lazy(() => import("./Main"));

function Views() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Switch>
        <Route path="/login" component={Login} />
        <PrivateRoute path="/" exact>
          <div
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Header />
            <Main />
          </div>
        </PrivateRoute>
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
}

export default Views;

import React, { createContext, useState, useEffect, useMemo } from "react";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

import firebase from "../firebase";

export const AppContext = createContext({});

function AppContextProvider(props) {
  const [date, setDate] = useState(moment());
  const [user, setUser] = useState();

  const startOfView = useMemo(
    () => date.clone().startOf("day").add(8, "hour"),
    [date]
  );
  const endOfView = useMemo(() => date.clone().endOf("day"), [date]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user?.toJSON() || null);
    });
  }, [setUser]);

  return user === undefined ? (
    <CircularProgress />
  ) : (
    <AppContext.Provider
      value={{ user, date, setDate, startOfView, endOfView }}
      {...props}
    />
  );
}

export default AppContextProvider;

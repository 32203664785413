import React, { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { AppContext } from "../../context";

function PrivateRoute(props) {
  const { user } = useContext(AppContext);
  const location = useLocation();

  return user ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: location },
      }}
    />
  );
}

export default PrivateRoute;
